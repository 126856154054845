.banner {
  color: whitesmoke;
  height: 448px;
}

.banner__contents {
  padding-left: 30px;
  padding-top: 124px;
}

.banner__title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.3rem;
}

.MuiButton-root {
  font-weight: 600 !important;
  margin-right: 20px !important;
}

.banner__buttons {
  padding-bottom: 1rem;
}

.info:hover {
  background-color: rgba(89, 88, 89, 0.7) !important;
}

.banner__description {
  background-color: rgba(1, 1, 1, 0.3);
  border-radius: 5%;
  text-shadow: 1px 1px 1px black;
  line-height: 1.3rem;
  max-width: 300px;
  width: 40rem;
  font-size: 0.8rem;
  font-weight: 600;
  height: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.banner--fadeBottom {
  height: 8.2rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61),
    #111
  );
}
