.nav {
  z-index: 1;
  position: fixed;
  top: 0;
  padding: 20px;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: background-color 300ms ease-in;
}

.show {
  background-color: #111;
}
.logo {
  width: 80px;
  position: fixed;
  left: 20px;
}

.avatar {
  width: 30px;
  position: fixed;
  right: 20px;
  transition: transform 100ms ease-in;
}

.avatar:hover {
  transform: scale(1.04);
}

.author_dark {
  padding-top: 5px;
  color: white;
  position: fixed;
  right: 60px;
}

.author {
  padding-top: 5px;
  color: black;
  position: fixed;
  right: 60px;
}

.author a {
  background-color: rgba(1, 1, 1, 0.4);
  text-decoration: none;
  font-weight: 700;
  color: rgba(251, 251, 251, 0.6);
}

.author_dark a {
  text-decoration: none;
  color: rgba(251, 251, 251, 0.9);
}

.author a:hover {
  text-decoration: underline;
  color: white;
}
