* {
  margin: 0;
}

.row {
  margin-left: 20px;
}

.app {
  background-color: #111;
}
