.row {
  color: white;
  margin-top: 15px;
}
.row__posters {
  object-fit: contain;
  display: flex;
  overflow-x: scroll;
  /* overflow: hidden; */
  padding: 20px;
  align-items: center;
}

.row__poster {
  object-fit: contain;
  width: 100%;
  max-height: 100px;
  margin-right: 10px;
  transition: transform 400ms;
}

.row_img {
  object-fit: contain;

  max-height: 100px;
  margin-right: 10px;
  transition: transform 400ms;
}

.netflix_ogs {
  max-height: 250px;
}
.netflix_og_holder {
  max-height: 250px;
}
.netflix_ogs:hover {
  transform: scale(1.08);
}

.row__poster:hover {
  transform: scale(1.08);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.row__posters::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.row__posters {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
